import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';

import { AdministratorGuard } from './guards/administrator-guard';

export const autoServiceRoutes: Routes = [
  {
    canActivate: [authGuardFn],
    children: [
      {
        data: { breadcrumb: 'Artikelbeheer' },
        loadChildren: () => import('../modules/articles/article.routing').then(r => r.articleRoutes),
        path: 'articles',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Artikelgroepbeheer' },
        loadChildren: () => import('../modules/article-groups/article-group.routing').then(r => r.articleGroupRoutes),
        path: 'article-groups',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Artikeltransacties' },
        loadChildren: () =>
          import('../modules/article-transactions/article-transactions.routing').then(r => r.articleTransactionRoutes),
        path: 'article-transactions',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Prijsbeheer' },
        loadChildren: () => import('../modules/prices/prices.routing').then(r => r.priceRoutes),
        path: 'prices',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Leveranciersbeheer' },
        loadChildren: () => import('../modules/suppliers/suppliers.routing').then(r => r.SupplierRoutes),
        path: 'suppliers',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Klantbeheer' },
        loadChildren: () => import('../modules/customers/customers.routing').then(r => r.CustomerRoutes),
        path: 'customers',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Gebruikersbeheer' },
        loadChildren: () => import('../modules/users/users.routing').then(r => r.UserRoutes),
        path: 'users',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Verkoopsbeheer' },
        loadChildren: () => import('../modules/sales/sales.routing').then(r => r.salesRoutes),
        path: 'sales',
      },
      {
        data: { breadcrumb: 'Aankoopbeheer' },
        loadChildren: () => import('../modules/purchases/purchase.routing').then(r => r.purchaseRoutes),
        path: 'purchases',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Bedrijfsbeheer' },
        loadChildren: () => import('../modules/companies/company.routing').then(r => r.companyRoutes),
        path: 'company',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Klantengroep' },
        loadChildren: () => import('../modules/relation-groups/relation-group.routing').then(r => r.relationGroupRoutes),
        path: 'customer-groups',
      },
      {
        data: { breadcrumb: 'Toonbankverkoop' },
        loadChildren: () => import('../modules/counter-sales/counter-sales.routing').then(r => r.CounterSalesRoutes),
        path: 'counter-sales',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Werknemers' },
        loadChildren: () => import('../modules/settings/employees.routing').then(r => r.employeeRoutes),
        path: 'employees',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Betaalmethodes' },
        loadChildren: () => import('../modules/settings/payment-methods.routing').then(r => r.paymentMethodRoutes),
        path: 'payment-methods',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Stock transacties' },
        loadChildren: () =>
          import('../modules/settings/stock-transaction-reasons.routing').then(r => r.stockTransactionReasonRoutes),
        path: 'stock-transaction-reasons',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Verzendkosten' },
        loadChildren: () => import('../modules/settings/delivery-costs.routing').then(r => r.deliveryCostRoutes),
        path: 'delivery-costs',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'BTW Percentages' },
        loadChildren: () => import('../modules/settings/vat-percentages.routing').then(r => r.vatPercentageRoutes),
        path: 'vat-percentages',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Gevaarlijke Producten' },
        loadChildren: () => import('../modules/settings/dangerous-products.routing').then(r => r.dangerousProductRoutes),
        path: 'dangerous-products',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'BTW Types' },
        loadChildren: () => import('../modules/settings/relation-vat-types.routing').then(r => r.relationVatTypeRoutes),
        path: 'relation-vat-types',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Prijstoeslagen' },
        loadChildren: () => import('../modules/settings/price-surgage.routing').then(r => r.priceSurgageRoutes),
        path: 'price-surgages',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Etiketten' },
        loadChildren: () => import('../modules/labels/lables.routing').then(r => r.labelRoutes),
        path: 'labels',
      },
      {
        canActivate: [AdministratorGuard],
        data: { breadcrumb: 'Inlezen artikels' },
        loadChildren: () => import('../modules/excel-templates/excel-template.routing').then(r => r.routes),
        path: 'excel-templates',
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'counter-sales',
      },
    ],
    data: { breadcrumb: 'Home' },
    path: '',
  },
];
